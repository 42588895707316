.site-header {
	min-height: 90px;
	padding-top: 10px;
	margin: 0 auto;
	background-color: white;
	border-bottom: 1px solid rgba($brand-color, 0.1);
	box-sizing: border-box;
	overflow: visible;
}

.logo {
	margin: 0;

	a {
		margin-top: 7px;
		display: block;
		width: 100px;
		background-image: url('../img/logoFiasSideFull.svg');
		background-repeat: no-repeat;
		width: 143px;
		background-size: 143px;
		height: 56px;
		text-indent: -9999px;
		overflow: hidden;
		float: left;

		@media #{$retina}  {
	    	background-image: url('../img/logoFiasSideFull.svg');
	    	background-size: 143px;
	    }
	}
}

.plain-header {
	background: #f4f4f4;
	padding: 40px;
	text-align: center;

	h2 {
		font-size: 36px;
		font-weight: 700;
		color: $font-special;
		margin: 0;
	}

	h3 {
		font-size: 20px;
		font-weight: 300;
		margin: .5em 0 0 0;
	}
}

.content-subhead {
	margin: 50px 0 20px 0;
	font-weight: 300;
	color: #888;
}

.content {
	img {
		max-width: 100%;
		border-radius: 2px;
	}

	ul {
		li {
			margin-bottom: 10px;
		}
	}
}

.content-narrow {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.site-footer {
	padding-top: 70px;
	background-color: #2f2f2f;
	margin-top: 60px;
	font-size: 13px;
	text-align: center;
	color: rgba(273,273,273,0.4);

	a {
		text-decoration: none;
		color: rgba(273,273,273,0.6);
	}

	.copyright {
		background-color: #222222;
		min-height: 70px;

		p {
			margin: 0;
			padding: 30px 0;
			a {
				color: white;
			}
		}
	}

	.footer-logo {
		max-width: 142px;
	}

	.footer-col {
		text-align: left;
		margin-bottom: 70px;

		h3 {
			margin: 0 0 .7em;
			color: $font-light;
			width: 75%;
			padding-bottom: 5px;
			border-bottom: 1px solid #555;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				margin: 5px 0;
				line-height: 25px;

				a {
					color: rgba($font-light, 0.6);

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

#banner + .site-footer {
	margin-top: 0px;
}

.search-box {
	position: relative;

	input {
		border:  1px solid color($link-color tint(30%));
		border-radius: 4px;
		width: 100%;
	}

	.icon-search {
		position: absolute;
    color: $link-color;
    right: 12px;
	  top: 12px;
	}

	&.search-box-lg {
		width: 400px;
		margin: 40px auto;

		input {
			padding: 10px 40px 10px 20px;
			font-size: 20px;
		}

		.icon-search {
	    font-size: 29px;
		}
	}

	&.search-box-sm {
		width: 300px;
		margin: 10px 0;
		float: right;

		input {
			padding: 5px 35px 5px 10px;
			font-size: 15px;
		}

		.icon-search {
			top: 9px;
	    font-size: 18px;
		}
	}
}

.doc-edit {
	float: right;
	opacity: .5;
	font-size: 20px;

	&:hover {
		opacity: 1;
	}
}

.expanding-faq {
	padding: 12px 20px;
	background-color: #f3f3f3;
	margin: 10px 0;
	border-radius: 3px;
	cursor: pointer;

	&.hover {
		background-color: #eee;
	}

	&:first-child {
		margin-top: 30px;
	}

	h2 {
		margin: 0;
		font-size: 18px;

		a {
			text-decoration: none;
			display: block;
			color: $font-dark;
		}

		.icon {
			float: right;
		}
	}

	.answer {
		display: none;
	}
}

.section-heading {
	border-bottom: $thin-line;
	padding: 5px;
	font-weight: 700;
	color: $brand-color;
	margin-bottom: 2em;
}

hr {
	margin: 2em auto;
	border-width: 0;
	height: 1px;
	background: #eee;
}
